import React from 'react'
import { Layout } from '../components/Layout'
import SEO from '../components/seo'
import { H2, H5 as Title } from '../components/Title'
import { Navigation } from '../components/Navigation'
import { Column, Row, Container } from '../components/Grid'
import { Paragraph } from '../components/Text'
import styled from '@emotion/styled'

const H5 = styled(Title)`
  margin-bottom: 20px;
`

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy verklaring" />

    <Navigation />

    <Container>
      <Row>
        <Column
          display={{ md: 10 }}
          offset={{ md: 1 }}
          style={{ marginBottom: 40 }}>
          <H2 style={{ color: 'black', marginTop: 60, marginBottom: 20 }}>
            Privacy verklaring
          </H2>
          <Paragraph>
            Fienefleur, gevestigd aan Dijkbeemd 18 2310 Rijkevorsel, is
            verantwoordelijk voor de verwerking van persoonsgegevens zoals
            weergegeven in deze privacyverklaring.
            <br />
            Contactgegevens: https://fienefleur.be Dijkbeemd 18 2310 Rijkevorsel
            +32 495 77 61 21
            <br />
            Tinne Van Wesenbeeck is de Functionaris Gegevensbescherming van
            Fienefleur Hij/zij is te bereiken via tinnevw@gmail.com
          </Paragraph>
          <H5>Persoonsgegevens die wij verwerken </H5>
          <Paragraph>
            Fienefleur verwerkt uw persoonsgegevens doordat u gebruik maakt van
            onze diensten en/of omdat u deze zelf aan ons verstrekt. Hieronder
            vindt u een overzicht van de persoonsgegevens die wij verwerken: -
            Voor- en achternaam - Telefoonnummer - E-mailadres
          </Paragraph>
          <H5>Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</H5>
          <Paragraph>
            Onze website en/of dienst heeft niet de intentie gegevens te
            verzamelen over websitebezoekers die jonger zijn dan 16 jaar. Tenzij
            ze toestemming hebben van ouders of voogd. We kunnen echter niet
            controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan
            ook aan betrokken te zijn bij de online activiteiten van hun
            kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld
            worden zonder ouderlijke toestemming. Als u er van overtuigd bent
            dat wij zonder die toestemming persoonlijke gegevens hebben
            verzameld over een minderjarige, neem dan contact met ons op via
            hello@cupofcode.be, dan verwijderen wij deze informatie.
          </Paragraph>
          <H5>
            Met welk doel en op basis van welke grondslag wij persoonsgegevens
            verwerken
          </H5>
          <Paragraph>
            Fienefleur verwerkt uw persoonsgegevens voor de volgende doelen: - U
            te kunnen bellen of e-mailen indien dit nodig is om onze
            dienstverlening uit te kunnen voeren
          </Paragraph>
          <H5>Geautomatiseerde besluitvorming</H5>
          <Paragraph>
            Fienefleur neemt geen besluiten over zaken die (aanzienlijke)
            gevolgen kunnen hebben voor personen op basis van geautomatiseerde
            verwerkingen. Het gaat hier om besluiten die worden genomen door
            computerprogramma's of -systemen, zonder dat daar een mens
            (bijvoorbeeld een medewerker van Fienefleur) tussen zit.
          </Paragraph>
          <H5>Hoe lang we persoonsgegevens bewaren</H5>
          <Paragraph>
            Fienefleur bewaart persoonsgegevens niet langer dan noodzakelijk
            voor het doel waarvoor deze zijn verstrekt dan wel op grond van de
            wet is vereist. De gegevens worden niet langer dan één jaar bewaard.
          </Paragraph>
          <H5>Delen van persoonsgegevens met derden</H5>
          <Paragraph>
            Fienefleur verstrekt uitsluitend aan derden en alleen als dit nodig
            is voor de uitvoering van onze overeenkomst met u of om te voldoen
            aan een wettelijke verplichting.
          </Paragraph>
          <H5>Cookies, of vergelijkbare technieken, die wij gebruiken</H5>
          <Paragraph>
            Fienefleur gebruikt geen cookies of vergelijkbare technieken.
          </Paragraph>
          <H5>Gegevens inzien, aanpassen of verwijderen</H5>
          <Paragraph>
            U heeft het recht om uw persoonsgegevens in te zien, te corrigeren
            of te verwijderen. Daarnaast heeft u het recht om uw eventuele
            toestemming voor de gegevensverwerking in te trekken of bezwaar te
            maken tegen de verwerking van uw persoonsgegevens door Fienefleur en
            heeft u het recht op gegevensoverdraagbaarheid. Dat betekent dat u
            bij ons een verzoek kunt indienen om de persoonsgegevens die wij van
            u beschikken in een computerbestand naar u of een ander, door u
            genoemde organisatie, te sturen. U kunt een verzoek tot inzage,
            correctie, verwijdering, gegevensoverdraging van uw persoonsgegevens
            of verzoek tot intrekking van uw toestemming of bezwaar op de
            verwerking van uw persoonsgegevens sturen naar hello@cupofcode.be.
            Om er zeker van te zijn dat het verzoek tot inzage door u is gedaan,
            vragen wij u een kopie van uw identiteitsbewijs met het verzoek mee
            te sturen. Maak in deze kopie uw pasfoto, MRZ (machine readable
            zone, de strook met nummers onderaan het paspoort), paspoortnummer
            en Burgerservicenummer (BSN) zwart. Dit ter bescherming van uw
            privacy. We reageren zo snel mogelijk, maar binnen vier weken, op uw
            verzoek.
          </Paragraph>
          <H5>Hoe wij persoonsgegevens beveiligen</H5>
          <Paragraph>
            Fienefleur neemt de bescherming van uw gegevens serieus en neemt
            passende maatregelen om misbruik, verlies, onbevoegde toegang,
            ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan.
            Als u de indruk heeft dat uw gegevens niet goed beveiligd zijn of er
            aanwijzingen zijn van misbruik, neem dan contact op via
            tinnevw@gmail.com
          </Paragraph>
        </Column>
      </Row>
    </Container>
  </Layout>
)

export default PrivacyPage
